import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ "topic" ]
		
  static values = {
    url: String
  }
    
  initialize() {
    $(this.topicTarget).focus()
    this.index = 0
    this.answer = ""
  }
	
	generate(event) {
		event.preventDefault();
		window.loader = $("#dialog-loader").dialog({
		    dialogClass: "no-close",
		    modal: true
		});
		window.query_dialog = $('#seo-queries').dialog({
		  autoOpen: false,
		  height: 640,
		  width: 800,
		  modal: true,
		  buttons: {
		    Cancel: function() {
		      window.query_dialog.dialog('close');
		    }
		  }
		});
		setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'}); $('.ui-widget-overlay').css({height: $( document ).height() + 'px'})}, 50);
		var input_data = { 
			authenticity_token: $('meta[name="csrf-token"]').attr('content'),
		  topic: this.topicTarget.value
		}
		$.ajax({
		  context: this,
		  async: true,
		  type: "POST",
		  url: this.urlValue,
		  data: input_data,
		  dataType: 'html'
		}).done(function(data) {
	  		window.loader.dialog('close'); 
		    setTimeout((function() {
		      $('#seo-queries-content').html(data);
		      $('#wip-queries').show();
					setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'}); $('.ui-widget-overlay').css({height: $( document ).height() + 'px'})}, 50);
		      window.query_dialog.dialog('open');
		    }), 100);
		}).fail(function( jqXHR, textStatus ) {
	  	  window.loader.dialog('close');
				alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
		});
	}
}