import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "styleSample" ]
    
	styleSample(event) {
    if(this.styleSampleTarget.value=='') return;
		event.preventDefault();
		window.query_dialog = $('#seo-queries').dialog({
		  autoOpen: false,
		  height: 640,
		  width: 800,
		  modal: true,
		  buttons: {
		    Cancel: function() {
		      window.query_dialog.dialog('close');
		    }
		  }
		});
		var input_data = { 
			authenticity_token: $('meta[name="csrf-token"]').attr('content'),
		  style_id: this.styleSampleTarget.value
		}
		$.ajax({
		  context: this,
		  async: true,
		  type: "GET",
		  url: "/admin/styles/get",
		  data: input_data,
		  dataType: 'html'
		}).done(function(data) {        
        const sidebar = document.querySelector('#model_settings');
        sidebar.classList.remove('settings_open')
		    setTimeout((function() {
		      $('#seo-queries-content').html(data);
		      window.query_dialog.dialog('open');
		    }), 100);
		});
	}
}