import { Controller } from "stimulus"
import { TIMEOUT_MESSAGES } from './messages.js'

export default class extends Controller {
  static targets = [ "prompt", "container", "messages" ]
  static values = {
    url: String,
    remove: String,    
    copy: String
  }
  
  template(type) { 
	return '<div class="chat-message" data-action="mouseover->chat#overMessage mouseleave->chat#outMessage"> \
	    <div class="chat-role"> \
	      <span data-action="click->chat#changeRole">' + type + '</span> \
	    </div> \
	    <div class="chat-content"> \
	      <textarea maxlength="4000" rows=1 placeholder="Enter ' + type + ' message here" data-chat-target="messages" data-action="focus->chat#addActive focusout->chat#removeActive input->chat#autoSize"></textarea> \
	    </div> \
	    <div class="chat-message-remove"> ' +
        ((type == "AI") ? '<img class="copy-btn" src="' + this.copyValue + '", data-action="click->chat#copy" style="padding-top: 2px"> ' : '') +
    '<img src="' + this.removeValue +'" data-action="click->chat#removeMessage" style="float:right"> \
	    </div> \
	  </div>';
  }
  
  initialize() {
	this.promptTarget.focus()
    this.chat_messages = []
  }
  
  clear() {
	this.chat_messages.length = 0;
  	this.promptTarget.value = ''
	$('.chat-message').not('.add').remove();
	this.promptTarget.focus()
  }
  
  autoSize(event) {
	  var el = $(event.target)[0];
	  el.style.height = 0;
	  el.style.height = (el.scrollHeight) + "px";
  }
  
  addMessage() {
		var lastRole = $('.chat-message').not('.add');
		if(lastRole.length > 0) {
			lastRole = lastRole.last().find('.chat-role').text().replace(/\s/g, '');
		} else {
			lastRole = 'AI';
		}
		$('.chat-message.add').before(this.template((lastRole == 'USER') ? 'AI' : 'USER'));
		$('.chat-message').not('.add').last().find('textarea').focus();
		$('.chat-message').not('.add').last().addClass('active');
  }
	
	changeRole(event) {
		event.preventDefault();
		var role = ($(event.target).text() == "USER") ? "AI" : "USER";
		$(event.target).text(role);
		$(event.target).parents('.chat-message').find('textarea').attr('placeholder',"Enter " + role + " message here")
    if(role=="USER") {
      $(event.target).parents('.chat-message').find('.copy-btn').remove();
    } else {
      $(event.target).parents('.chat-message').find('.chat-message-remove').prepend('<img class="copy-btn" src="' + this.copyValue + '", data-action="click->chat#copy" style="padding-top: 2px"> ');
    }
	}
  
  removeMessage(event) {
		event.preventDefault();
		$(event.target).parents('.chat-message').remove();
  }
  
  copy(event) {
		var $temp = $("<textarea>");
		$("body").append($temp);
		$temp.val($(event.target).parents('.chat-message').find('.chat-content textarea').val()).select();
		document.execCommand("copy");
		$temp.remove();
		$('.flashes').show();
		setTimeout(() => { $('.flashes').hide()} , 2000);
  }
  
  overMessage(event) {
		event.preventDefault();
		if(!$(event.target).parent('.chat-message').hasClass('active'))
			$(event.target).parent('.chat-message').find('.chat-message-remove > img').css('display', 'inline-block')
  }
  
  outMessage(event) {
	event.preventDefault()
	if(!$(event.target).find('textarea').is(":focus")) 
		$(event.target).find('.chat-message-remove > img').css('display', 'none');
  }
  
  addActive(event) {
	 var close = $(event.target).parent().parent().find('.chat-message-remove')[0];
	 $('.chat-message-remove').each(function(idx, el) {
		 if(close != el) 
	 		 $(el).find('img').css('display', 'none');
	 });
  }
  
  removeActive(event) {
	  event.preventDefault();
	  $(event.target).parent().parent().removeClass('active');
  }
  
  generate(event) {
		event.preventDefault();
		if(this.promptTarget.value.length > 0)
			this.chat_messages.unshift({role: "system", content: this.promptTarget.value});
		else
			this.chat_messages.unshift({role: "system", content: 'Helpful assistant'});
		var that=this;
		$('.chat-message').not('.add').each(function(idx, el) {
			that.chat_messages.push({
				role: ($(el).find('.chat-role').text().replace(/\s/g, '') == 'USER') ? 'user' : 'system', 
				content: $(el).find('.chat-content textarea').val()
			})
		});
		window.loader = $("#dialog-loader").dialog({
		    dialogClass: "no-close",
		    modal: true
		});
		setTimeout(() => { $('.ui-widget-overlay').css({opacity:'0.75'})}, 50);
		$.ajax({
		  context: this,
		  async: true,
		  type: "POST",
		  url: this.urlValue,
		  data: { 
			  authenticity_token: $('meta[name="csrf-token"]').attr('content'), 
			  prompt: JSON.stringify(this.chat_messages),
				model: $('input[name="model"]:checked').val(),
        apply_kb: $('input[name="content[apply_kb]"]:checked').val(),
        style: $('#content_style').val()
		  },
		  dataType: 'json',
		  timeout: 120000
		}).done(function(data) {
	  		window.loader.dialog('close');		 
	  		if (typeof data[0] === 'number') {
					this.chat_messages.length = 0;
					if(data[0] == 0)
	  				setTimeout(() => { alert("Smth went wrong. Please try again later! Error type:\n" + data[1])}, 500);
					else
						setTimeout(() => { alert(data[1])}, 500);
	  		} else { 
					this.chat_messages.length = 0;
					$('.chat-message.add').before(this.template('AI'));
					var el = $('.chat-message').not('.add').last().find('textarea');
					el.val(data[0]);
					el[0].style.height = 0;
					el[0].style.height = (el[0].scrollHeight) + "px";
					$('#response').animate({scrollTop: $('#response').get(0).scrollHeight}, 2000);  
	  		}
		}).fail(function( jqXHR, textStatus ) {
			window.loader.dialog('close');
			this.chat_messages.length = 0;
			alert( TIMEOUT_MESSAGES[Math.floor(Math.random()*TIMEOUT_MESSAGES.length)] );
		});
  }
}